import React, { ReactElement, useState, useEffect } from 'react';
import { FileShape } from '../interfaces/files';
import { DateTime } from './DateTime';
import { Copy } from './Copy';
import { FormatBytes } from './FormatBytes';
import { Button } from './Button';
import SideMenu from './SideMenu';
import { TextInput } from './TextInput';
import { callApi } from '../functions/callApi';
import { useGlobalUserState } from '../hooks/useGlobalUserState';
import { SelectInput } from './SelectInput';
import { ServerError } from './ServerError';
import useFilesTransfer from '../hooks/useFilesTransfer';
import { Heading } from './Heading';
import { ShareModal } from './ShareModal';
import { getFilePassword, getPrivateKey } from '../functions/encryptions';

export interface PropsShape {
  data: FileShape;
  layout: 'ROW' | 'SMALL' | 'LARGE';
  folders?: FileShape[];
}

interface isOpenKeyModalShape {
  decryptFile: boolean;
  decryptPrivateKey: boolean;
}

const FileView = ({ data, layout, folders }: PropsShape): ReactElement => {
  const { userState, setUserState } = useGlobalUserState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [renameLoading, setRenameLoading] = useState<boolean>(false);
  const [encryptLoading, setEncryptLoading] = useState<boolean>(false);
  const [rename, setRename] = useState<string>('');
  const [display, setDisplay] = useState<boolean>(false);
  const [moveTo, setMoveTo] = useState<string>('');
  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [renameError, setRenameError] = useState<string>('');
  const [isOpenMasterKeyModal, setIsOpenMasterKeyModal] =
    useState<isOpenKeyModalShape>({
      decryptFile: false,
      decryptPrivateKey: false,
    });
  const [serverError, setServerError] = useState<string>('');
  const [masterKey, setMasterKey] = useState<string>('');
  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const [decryptLoading, setDecryptLoading] = useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);
  const {
    doUpload,
    uploading,
    setUploading,
    uploadError,
    filesToUpload,
    batchSize,
    batchesToUpload,
    isProgressValid,
    progress,
    doDownload,
    downloadLoading,
    downloadError,
  } = useFilesTransfer();

  useEffect(() => {
    setConfirmDelete('');
    setRenameError('');
    if (isOpen) {
      setRename(data.name);
    } else {
      setRename('');
    }
  }, [isOpen]);

  useEffect(() => {
    setAddDisabled(!masterKey);
  }, [masterKey]);

  useEffect(() => {
    confirmDelete === 'delete' && setDeleteDisabled(false);
  }, [confirmDelete]);

  const canDisplay = (): void => {
    setDisplay(true);
  };

  const doDownloadHandler = async (): Promise<void> => {
    if (data.encrypted) {
      setIsOpenMasterKeyModal((prevState): any => ({
        ...prevState,
        decryptFile: true,
      }));
    } else await doDownload(data, false);
  };

  const doDelete = async (): Promise<void> => {
    setDeleteLoading(true);
    const url = data.shared ? 'encryption/file/share' : 'file';
    const body =
      data.shared && userState.currentOrganization?.accountKey
        ? {
            account_key: userState.currentOrganization?.accountKey,
            file_hash: data.hash,
            file_key: data.key,
          }
        : data.shared
        ? {
            account_key: userState?.data?.accountKey,
            file_hash: data.hash,
            file_key: data.key,
          }
        : {
            file_hash: data.hash,
            file_key: data.key,
          };
    try {
      await callApi(url, 'DELETE', JSON.stringify(body));
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
      setDeleteLoading(false);
      setIsOpen(false);
    }
  };

  const doRename = async (): Promise<void> => {
    setRenameLoading(true);
    const url = 'file/rename';
    const body = {
      file_hash: data.hash,
      file_name: rename,
      file_key: data.key,
    };
    await callApi(url, 'PATCH', JSON.stringify(body));
    setUserState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        lastUpload: Date.now(),
      },
    }));
    setRenameLoading(false);
    setIsOpen(false);
  };

  const doMove = async (): Promise<void> => {
    setMoveLoading(true);
    const url = 'file/move';
    const body = {
      file_key: data.key,
      folder_key: moveTo,
    };
    try {
      await callApi(url, 'PATCH', JSON.stringify(body));
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setMoveLoading(false);
      setIsOpen(false);
      setUserState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          lastUpload: Date.now(),
        },
      }));
    }
  };

  const doEncrypt = async (): Promise<void> => {
    try {
      setEncryptLoading(true);
      let previewSrc;
      const preview = document.getElementById('preview');
      if (preview) {
        previewSrc = preview.getAttribute('src');
      } else previewSrc = `${process.env.REACT_APP_API_IPFS_URL}${data.hash}`;
      if (previewSrc) {
        const fileData = await fetch(previewSrc);
        const blob = await fileData.blob();
        const file = new File([blob], `${data.name} - encrypted`, {
          type: blob.type,
        });
        await doUpload([file], true, [data.key]);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setEncryptLoading(false);
    }
  };

  const doDecrypt = async (): Promise<void> => {
    try {
      setDecryptLoading(true);
      const privateKey: ArrayBuffer = await getPrivateKey(
        masterKey,
        setServerError
      );
      const fileDecryptionParams = await getFilePassword(
        privateKey,
        data,
        setServerError
      );
      await doDownload(data, true, fileDecryptionParams);
      setIsOpenMasterKeyModal((prevState): any => ({
        ...prevState,
        decryptFile: false,
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setDecryptLoading(false);
    }
  };

  const calcFolders = (): any => {
    if (folders) {
      const foldersArr = [];
      for (let i = 0; i < folders.length; i++) {
        foldersArr.push({
          value: `${folders[i].key}`,
          name: folders[i].name,
        });
      }

      return foldersArr;
    }
  };
  return (
    <>
      {layout === 'SMALL' && (
        <div
          className='FileView'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileView--top'>
            <div className='FileView--top-name'>{data.name}</div>
          </div>
          <div className='FileView--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      {layout === 'LARGE' && (
        <div
          className='FileView'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileView--top'>
            <div className='FileView--top-folder' />
            <div className='FileView--top-name'>{data.name}</div>
            <div className='FileView--top-date'>
              <DateTime showTime value={new Date(data.created)} />
            </div>
          </div>
          <div className='FileView--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      {layout === 'ROW' && (
        <div
          className='FileViewRow'
          onClick={(): void => setIsOpen(true)}
          onContextMenu={(e: any): void => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <div className='FileViewRow--folder' />
          <div className='FileViewRow--name'>{data.name}</div>
          <div className='FileViewRow--date'>
            <DateTime showTime value={new Date(data.created)} />
          </div>
          <div className='FileViewRow--size'>
            <FormatBytes value={+data.size!} />
          </div>
          <div className='FileViewRow--bottom'>
            <Copy
              value={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
              type='TEXT'
              text={data.hash}
            />
          </div>
        </div>
      )}
      <SideMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position='RIGHT'
        width='REGULAR'
      >
        <div className='FileViewRow--grid'>
          <div>
            <div className='FileViewRow--section'>
              {userState.data?.isPreviewOn && (
                <img
                  src={`${process.env.REACT_APP_API_IPFS_URL}${data.hash}/`}
                  id='preview'
                  onLoad={(): void => canDisplay()}
                  className={
                    display ? 'FileViewRow--show' : 'FileViewRow--hide'
                  }
                ></img>
              )}
              <div
                className='FileViewRow--info'
                style={{ marginBottom: `24px` }}
              >
                <div className='FileViewRow--info-left'>Name</div>
                <div className='FileViewRow--info-right'>{data.name}</div>
                <div className='FileViewRow--info-left'>Created</div>
                <div className='FileViewRow--info-right'>
                  <DateTime showTime value={new Date(data.created)} />
                </div>
                <div className='FileViewRow--info-left'>Size</div>
                <div className='FileViewRow--info-right'>
                  <FormatBytes value={+data.size!} />
                </div>
                <div className='FileViewRow--info-left'>Hash</div>
                <div className='FileViewRow--info-right'>{data.hash}</div>
              </div>
              <ServerError error={downloadError || renameError} />
              <Button
                name='Download'
                color='GREY'
                loading={downloadLoading}
                click={doDownloadHandler}
              />
              {((userState.data?.isActive &&
                userState.data?.encryption?.publicKey) ||
                (userState.currentOrganization?.accountKey &&
                  userState.currentOrganization.encryption?.publicKey)) &&
                !data.encrypted && (
                  <Button
                    name='Encrypt'
                    color='BLUE'
                    loading={encryptLoading}
                    click={doEncrypt}
                  />
                )}
              {data.encrypted && !data.shared && (
                <>
                  {((userState.data?.isActive &&
                    userState.data?.encryption?.publicKey) ||
                    (userState.currentOrganization?.accountKey &&
                      userState.currentOrganization.encryption?.publicKey)) && (
                    <Button
                      name='Share'
                      color='BLUE'
                      click={(): void => setIsOpenShareModal(true)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div />
          {!data.shared && (
            <div>
              <hr className='FileViewRow--divider' />
              <div className='FileViewRow--section'>
                <div style={{ maxWidth: '100%' }}>
                  <div style={{ color: '#838383', marginBottom: '3px' }}>
                    Move file to...
                  </div>
                  <SelectInput
                    name='Move file to'
                    value={moveTo}
                    setValue={setMoveTo}
                    options={calcFolders()}
                  />
                </div>
                <Button
                  name='Move'
                  color='BLUE'
                  loading={moveLoading}
                  click={doMove}
                />
              </div>
            </div>
          )}
          {!data.shared && (
            <div>
              <hr className='FileViewRow--divider' />
              <div className='FileViewRow--section'>
                <TextInput
                  name='File name'
                  value={rename}
                  setValue={setRename}
                  label
                />
                <Button
                  name='Rename'
                  color='BLUE'
                  loading={renameLoading}
                  click={doRename}
                />
              </div>
            </div>
          )}
          {userState.data?.canUpload && !userState.data?.isFolder && (
            <div>
              <hr className='FileViewRow--divider' />
              <div className='FileViewRow--section'>
                <TextInput
                  name='Confirm delete'
                  value={confirmDelete}
                  setValue={setConfirmDelete}
                  placeHolder="Enter 'delete' to confirm"
                  label
                />
                <Button
                  name='Delete'
                  color='RED'
                  disabled={deleteDisabled}
                  click={doDelete}
                  loading={deleteLoading}
                />
              </div>
            </div>
          )}
        </div>
      </SideMenu>
      <SideMenu isOpen={uploading} setIsOpen={setUploading} position='CENTER'>
        <div className='Header--uploading'>
          <Heading
            title='Encryption'
            subtitle='Please be patient while your files are encrypted.'
          />
          {uploadError && (
            <ServerError
              error={`${uploadError?.error} ${uploadError?.message}`}
            />
          )}
          <p>
            Uploading {filesToUpload} files in batches of {batchSize} chunks
          </p>
          <p>{batchesToUpload} batches left to upload.</p>
          <div className='Header--progress-bar'>
            {isProgressValid && (
              <div
                className='Header--progress'
                style={{
                  width: `${progress}%`,
                }}
              ></div>
            )}
          </div>
        </div>
      </SideMenu>
      <SideMenu
        isOpen={isOpenMasterKeyModal.decryptFile}
        setIsOpen={(): any =>
          setIsOpenMasterKeyModal((prevState) => ({
            ...prevState,
            decryptFile: false,
          }))
        }
        position='CENTER'
        width='REGULAR'
      >
        <div style={{ padding: '24px' }}>
          <Heading
            title='Enter master key'
            subtitle='Enter the master key to decrypt your file'
          />
          <ServerError error={serverError} />
          <TextInput
            type='text'
            name='Master Key'
            value={masterKey}
            setValue={setMasterKey}
            label
            placeHolder={
              userState.currentOrganization?.encryption?.selfHint ||
              userState.data?.encryption?.selfHint
            }
          />

          <Button
            name='Decrypt'
            click={doDecrypt}
            loading={decryptLoading}
            disabled={addDisabled}
          />
        </div>
      </SideMenu>
      <ShareModal
        isOpen={isOpenShareModal}
        setIsOpen={setIsOpenShareModal}
        file={data}
      />
    </>
  );
};
export { FileView };
